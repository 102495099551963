//Components
import { Accordion, Text } from "@mobi/ds"

//Data
import { accordionData } from "./dataContent"

//Style
import "./index.scss"

const Faq = () => {
  return (
    <section className="benefits-faq">
      <div className="benefits-faq__container">
        <Text as="h2" size={"xxl"} className="benefits-faq__title">
          dúvidas sobre os benefícios?
        </Text>
        <div
          className="benefits-faq__list"
          aria-label="dúvidas sobre os benefícios?"
        >
          <Accordion items={accordionData} />
        </div>
      </div>
    </section>
  )
}

export default Faq
