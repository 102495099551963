import { useState, useEffect, useCallback } from "react"
import { HOMEPATH } from "gatsby-env-variables"

// Components
import Header from "components/Header"
import Layout from "components/Layout"
import { Footer, Text } from "@mobi/ds"
import { Icon } from "@vehicles/components"
import Hero from "./components/Hero"
import Navigation from "./components/Navigation"
import AutoInsurance from "./components/AutoInsurance"
import Icarros from "./components/Icarros"
import Banner from "./components/Banner"
import Faq from "./components/Faq"

//Utils
import isClient from "@mobi/utils/browser/isClient"

//Style
import "./index.scss"

const Benefits = () => {
  const [isPageBottom, setIsPageBottom] = useState(false)

  const handleScroll = useCallback(() => {
    const isWindowAtBottom =
      window.innerHeight + Math.ceil(window.scrollY) >=
      document.body.offsetHeight

    if (isWindowAtBottom) {
      setIsPageBottom(true)
    } else {
      setIsPageBottom(false)
    }
  }, [])

  useEffect(() => {
    if (isClient()) {
      window.addEventListener("scroll", handleScroll)
      return () => window.removeEventListener("scroll")
    }
  }, [handleScroll])

  return (
    <Layout className="benefits">
      <Header
        isHome={true}
        prevUrl={HOMEPATH}
        dynamicLogo={"itau"}
        linkLogoUrl={HOMEPATH}
        isActiveButtonAccess={false}
        mobileInfosApp={false}
      />
      <Hero />
      <Navigation />
      <AutoInsurance />
      <Icarros />
      <Banner />
      <Faq />
      <Footer
        links={{
          urlHelpPage: "https://www.itau.com.br/atendimento-itau/para-voce",
        }}
      />
      <section
        className={`benefits__baseboard ${isPageBottom ? "--fixed" : ""}`}
      >
        <a
          className="benefits__baseboard__container"
          href="https://veiculos.itau.com.br/simulacao"
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <Icon id="icon-car-outlined" />
          <Text as="p" size="md">
            gostou? faça já sua simulação
          </Text>
          <Icon id="icon-arrow-right" />
        </a>
      </section>
    </Layout>
  )
}

export default Benefits
