//Components
import { Text } from "@mobi/ds"
import Img from "components/Img"

//Style
import "./index.scss"

const Hero = () => {
  return (
    <section className="benefits-hero">
      <div className="benefits-hero__container">
        <div className="benefits-hero__info-text">
          <Text as="h1" size="xxl">
            confira os benefícios de comprar seu carro com a gente: )
          </Text>
          <Text size="xl">Compre seu carro junto de outras vantagens: </Text>
        </div>
        <div className="benefits-hero__image">
          <Img file="benefits-hero" alt="" role="presentation" />
        </div>
      </div>
    </section>
  )
}

export default Hero
