import { navigate } from "gatsby"

// Components
import { Icon } from "@vehicles/components"
import { Button, Text } from "@mobi/ds"

//Style
import "./index.scss"

const Banner = () => {
  return (
    <section className="banner">
      <div className="banner__container">
        <Icon id="icon-promo" />
        <div className="banner__text-display">
          <Text as="p" size="lg" weight="800" className="info-heading">
            aproveite cada benefício!
          </Text>
          <br aria-hidden="true" />
          <Text as="p" size="md" className="banner__info-description">
            Quando você financia seu carro com o Itaú, você garante esses
            benefícios exclusivos com a gente! Simule seu financiamento agora!
          </Text>
        </div>
        <Button variant="high-contrast" onClick={() => navigate("/")}>
          simular financiamento
        </Button>
      </div>
    </section>
  )
}

export default Banner
