//Components
import Img from "components/Img"
import { Link, Text } from "@mobi/ds"

//Style
import "./index.scss"

const AutoInsurance = () => {
  return (
    <section id="autoinsurance" className="benefits-autoinsurance">
      <div className="benefits-autoinsurance__container">
        <div className="benefits-autoinsurance__principal-info">
          <div className="benefits-autoinsurance__logo">
            <Img file="autoinsurance-logo" alt="" role="presentation" />
          </div>
          <div className="benefits-autoinsurance__flag">
            <Img
              file="flag-descont-10-percent"
              className="icon-head"
              alt=""
              role="presentation"
            />
          </div>
          <div className="benefits-autoinsurance__divider"></div>
          <Text
            as="p"
            size="xl"
            weight="700"
            className="benefits-autoinsurance__info-heading"
          >
            desconto de até 10% no Seguro Auto
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-autoinsurance__info-description"
          >
            Mantenha o seu carro protegido contra imprevistos e conte com
            assistência 24 horas.
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-autoinsurance__info-description benefits-autoinsurance__info-description--internspace"
            weight={800}
          >
            como resgatar
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-autoinsurance__info-description  benefits-autoinsurance__info-description--inline"
          >
            Para contratar o seguro com desconto, é só ligar no telefone{" "}
            <Link href="tel: 0800 727 1289" target="_blank" rel="noreferrer">
              0800 727 1289
            </Link>
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-autoinsurance__info-description benefits-autoinsurance__info-description--internspace"
            weight={800}
          >
            dúvidas
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-autoinsurance__info-description  benefits-autoinsurance__info-description --inline"
          >
            Ligue também para{" "}
            <Link href="tel: 0800 727 1289" target="_blank" rel="noreferrer">
              0800 727 1289
            </Link>
          </Text>
        </div>

        <div className="benefits-autoinsurance__image">
          <Img
            file="key-and-car"
            className="image"
            alt=""
            role="presentation"
          />
        </div>
      </div>
    </section>
  )
}

export default AutoInsurance
