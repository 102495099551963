import { Link } from "@mobi/ds"

export const accordionData = [
  {
    title: "Como o benefício de iCarros pode me ajudar?",
    children:
      "iCarros é o portal de anúncios de carros que recebe mais de 23 milhões de potenciais compradores todos os meses. É o lugar ideal para você anunciar seu carro com segurança, visibilidade e sem se preocupar em perder valores de revenda.",
  },
  {
    title: "Para anunciar meu carro no iCarros eu pago alguma taxa?",
    children:
      "O anúncio no plano Até Vender (anúncio fica ativo até o usuário desativar) é 100% gratuito para todos os clientes de Itaú: correntistas, cartonistas e que contrataram qualquer outro produto do banco desde que seja usado o cupom #EspecialItau. Limitação de 1 anúncio por CPF. Duração do cupom: até 30/06/2022.",
  },
  {
    title:
      "Já tinha contratado um benefício antes de fechar o financiamento com o Itaú, como faço para conseguir o meu desconto?",
    children: `As ótimas condições de financiamento do Itaú já estão incorporadas em seu contrato.

    Para as demais adesões de benefício: ConectCar, iCarros e Seguro Auto validar detalhes com canais de atendimento dos parceiros, disponíveis em “saiba mais” ou seguindo o passo a passo e regras vigentes de contratação para os clientes Itaú, disponíveis na aba “Saiba mais” de cada benefício.`,
  },
  {
    title:
      "Onde faço uma reclamação ou cancelamento do serviço/produto que contratei do parceiro?",
    children:
      "Você pode ligar na Central de Atendimento do próprio parceiro ou, em alguns casos, é possível falar pelos canais digitais (aplicativo, site, entre outros). Você pode encontrar os telefones ou e-mails para o contato na aba “Saiba mais” de cada benefício.",
  },
  {
    title: "Como resgato meus descontos?",
    children:
      "Cada benefício tem uma forma diferente para contratar, clique no botão “Saiba mais” de cada vantagem para mais informações. Vale lembrar que os resgates são feitos no próprio site dos parceiros.",
  },
  {
    title: "Pessoas jurídicas podem contratar os benefícios de desconto?",
    children:
      "Não, pessoas jurídicas não podem contratar tais serviços. Os benefícios são exclusivos para Pessoas Físicas.",
  },
  {
    title:
      "Após a contratação do financiamento, quanto tempo devo esperar para resgatar o meu benefício?",
    children:
      "O desconto leva até 4 dias úteis para ficar disponível no site dos nossos parceiros.",
  },
]
