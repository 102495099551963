//Components
import { Text } from "@mobi/ds"
import { Icon } from "@vehicles/components"

//Style
import "./index.scss"

const Navigation = () => {
  const goToItem = (id) => {
    const autoSection = document.querySelector(`#${id}`)
    autoSection.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <section className="benefits-navigation">
      <div className="benefits-navigation__container">
        <Text as="h2" size="xxl" className="benefits-navigation__title">
          selecione o melhor benefício para você :)
        </Text>
        <nav>
          <ul className="benefits-navigation__cards">
            <li className="benefits-navigation__card">
              <button
                className="benefits-navigation__card__body"
                onClick={() => goToItem("autoinsurance")}
              >
                <Icon id="icon-frame-car" />
                <Text as="p" size="md">
                  Seguro Auto
                </Text>
              </button>
            </li>

            <li className="benefits-navigation__card">
              <button
                className="benefits-navigation__card__body"
                onClick={() => goToItem("icarros")}
              >
                <Icon id="icon-icarros" />
                <Text as="p" size="md">
                  iCarros
                </Text>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  )
}

export default Navigation
