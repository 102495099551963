//Components
import Img from "components/Img"
import { Link, Text } from "@mobi/ds"

//Style
import "./index.scss"

const Icarros = () => {
  return (
    <section id="icarros" className="benefits-icarros">
      <div className="benefits-icarros__container">
        <div className="benefits-icarros__principal-info">
          <div className="benefits-icarros__logo">
            <Img
              file="icarros-logo-2x"
              className="icon-head"
              alt=""
              role="presentation"
            />
          </div>
          <div className="benefits-icarros__flag">
            <Img
              file="flag-descont-100-percent"
              className="icon-head"
              alt=""
              role="presentation"
            />
          </div>
          <div className="benefits-icarros__divider"></div>
          <Text as="p" size="xl" weight="700" className="info-heading">
            100% de desconto para anunciar no iCarros caso seja correntista
          </Text>
          <Text as="p" size="sm" className="benefits-icarros__info-description">
            O portal que recebe mais de 23 milhões de potenciais compradores
            todos os meses.
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-icarros__info-description"
            weight={800}
          >
            como resgatar
          </Text>
          <ol className="benefits-icarros__list">
            <li>
              <Link
                href="https://www.icarros.com.br/vender/index.jsp?cupom=%23EspecialItau&parceiro_id=198&endo=1559"
                target="_blank"
                rel="noreferrer"
              >
                Acesse o site;
              </Link>
            </li>
            <li>
              <Text
                as="p"
                size="sm"
                className="benefits-icarros__info-description"
              >
                Preencha as informações do seu carro;
              </Text>
            </li>
            <li>
              <Text
                as="p"
                size="sm"
                className="benefits-icarros__info-description"
              >
                Na área de pagamento, selecione o Plano Até Vender;
              </Text>
            </li>
            <li>
              <Text
                as="p"
                size="sm"
                className="benefits-icarros__info-description"
              >
                Caso o desconto não seja aplicado automaticamente, insira o
                cupom #EspecialItau;
              </Text>
            </li>
            <li>
              <Text
                as="p"
                size="sm"
                className="benefits-icarros__info-description"
              >
                Pronto. Seu anúncio será publicado.
              </Text>
            </li>
          </ol>
          <Text as="p" size="sm" weight={800}>
            Limitação de 1 anúncio por CPF. Duração do cupom: 1 ano;
          </Text>
          <Text
            as="p"
            size="sm"
            className="benefits-icarros__info-description"
            weight={800}
          >
            dúvidas
          </Text>
          <Text as="p" size="sm" className="benefits-icarros__info-description">
            Ainda não conhece o “Plano Até Vender” do iCarros?{" "}
            <Link
              href="https://icarros.zendesk.com/hc/pt-br/articles/216721718-Descri%C3%A7%C3%A3o-do-Plano-At%C3%A9-Vender"
              target="_blank"
              rel="noreferrer"
            >
              ir para o site iCarros
            </Link>
          </Text>{" "}
          <Text as="p" size="sm">
            {""}
          </Text>
          <Text as="p" size="sm" className="benefits-icarros__info-description">
            Outras dúvidas, mande um email para{" "}
            <Link
              href="mailto: faleconosco@icarros.com.br"
              target="_blank"
              rel="noreferrer"
            >
              faleconosco@icarros.com.br
            </Link>
          </Text>
        </div>
        <div className="benefits-icarros__image">
          <Img
            file="icarros-offer"
            className="image"
            alt=""
            role="presentation"
          />
        </div>
      </div>
    </section>
  )
}

export default Icarros
